import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Typography,
} from "@mui/joy";
import type { ImageRequest } from "../../../../backend/src/ai/images/imagesTypes.ts";
import { SegmentDisplay } from "../input/SegmentDisplay.tsx";
import { useTranslation } from "../../lib/i18n.ts";
import { SizeSelector } from "./SizeSelector.tsx";
import { DallEOptions } from "./DallEOptions.tsx";

type Amount = 1 | 2 | 3 | 4;

export function ImagePromptInputEditor({
  input,
  setInput,
  onGenerate,
}: {
  input: ImageRequest;
  setInput: (input: ImageRequest) => void;
  onGenerate: () => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <FormControl>
        <FormLabel>{t("prompt")}</FormLabel>
        <Textarea
          minRows={3}
          maxRows={6}
          value={input.prompt}
          onChange={(e) =>
            setInput({
              ...input,
              prompt: e.target.value,
            })
          }
          placeholder={t("images.promptPlaceholderEmpty")}
          endDecorator={
            <div className="flex w-full flex-row justify-end">
              <Button
                onClick={onGenerate}
                disabled={input.prompt.length === 0}
                size="lg"
              >
                {t("generate")}
              </Button>
            </div>
          }
        />
      </FormControl>
      <AccordionGroup size="sm">
        <Accordion
          sx={{
            border: "none",
          }}
        >
          <AccordionSummary>{t("images.advanced")}</AccordionSummary>
          <AccordionDetails>
            <div className="m-2 grid grid-cols-2 gap-2">
              <Typography>{t("amount")}</Typography>
              <SegmentDisplay<Amount>
                size="md"
                buttonFlex={1}
                options={[
                  {
                    label: "1",
                    value: 1,
                  },
                  {
                    label: "2",
                    value: 2,
                  },
                  {
                    label: "3",
                    value: 3,
                  },
                  {
                    label: "4",
                    value: 4,
                  },
                ]}
                value={input.numberOfImages as Amount}
                setValue={(value) =>
                  setInput({
                    ...input,
                    numberOfImages: value,
                  })
                }
              />
              <Typography>{t("images.aspectRatio")}</Typography>
              <SizeSelector input={input} setInput={setInput} />

              {input.model === "dall-e-3" && (
                <DallEOptions input={input} setInput={setInput} />
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </>
  );
}
