import { useState } from "react";
import { Button, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { useTranslation } from "../../lib/i18n";
import { useParams } from "../../router";

/**
 * Displays a generated image.
 * When the image is clicked, it opens a modal to preview the image in a larger view.
 * The modal also provides an option to download the image.
 *
 * @param {Object} props - The component props.
 * @param {string} props.url - The URL of the generated image.
 */
export function GeneratedImage({ url }: { url: string }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <PreviewModal
        imgSrc={url}
        open={modalOpen}
        setOpen={setModalOpen}
        key={url}
      />
      <img
        className="cursor-pointer transition-all hover:brightness-50"
        onClick={() => {
          setModalOpen(true);
        }}
        src={url}
        alt="Generated image"
        style={{
          width: "300px",
        }}
      />
    </>
  );
}

function PreviewModal({
  imgSrc,
  open,
  setOpen,
}: {
  imgSrc: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation();
  const { organizationId } = useParams("/:organizationId");

  const isDataUrl = imgSrc.startsWith("data:");

  //FIXME this is a dirty hack that will likely break at some point. We should use the API to download the image properly
  const fileExtension = isDataUrl ? imgSrc.split(";")[0].split("/")[1] : "png";

  const downloadUrl = isDataUrl
    ? imgSrc
    : `/api/organizations/${organizationId}/images/download?url=${encodeURIComponent(imgSrc)}`;

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalDialog>
        <ModalClose />
        <div className="flex min-w-max max-w-full flex-col gap-4">
          <Typography level="h4">{t("images.preview")}</Typography>
          <img
            src={imgSrc}
            alt="An AI Generated image"
            style={{
              maxWidth: "80vw",
              maxHeight: "80vh",
            }}
          />
          <div className="flex flex-row gap-2">
            <a href={downloadUrl} download={"generated." + fileExtension}>
              <Button>{t("download")}</Button>
            </a>
          </div>
        </div>
      </ModalDialog>
    </Modal>
  );
}
