import { Delete, Add } from "@mui/icons-material";
import {
  Typography,
  List,
  ListItem,
  FormLabel,
  IconButton,
  Stack,
  Button,
  FormControl,
  Input,
} from "@mui/joy";
import { getMonthName } from "../../lib/util";
import { useTranslation } from "react-i18next";
import { type WorkflowInput } from "apiTypes";

export function SelectionEditor({
  input,
  setInput,
}: {
  input: WorkflowInput;
  setInput: (input: WorkflowInput) => void;
}) {
  const { t, i18n } = useTranslation();
  return (
    <Stack gap={2}>
      <Typography level="title-lg">
        {t("workflowEditor.optionsFor", { input: input.name })}
      </Typography>
      <List
        sx={{
          borderRadius: 10,
          maxHeight: 500,
          overflowY: "auto",
          overflowX: "-moz-hidden-unscrollable",
        }}
        className="space-y-2 bg-neutral-500/5"
      >
        {input.options.length === 0 && (
          <Typography level="body-sm" color="neutral" alignSelf="center" my={1}>
            {t("workflowEditor.optionsError")}
          </Typography>
        )}
        {input.options.map((option, index) => (
          <ListItem key={index}>
            <Stack
              direction="row"
              width="100%"
              spacing={1}
              alignItems="flex-end"
            >
              <FormControl sx={{ flex: 1 }}>
                <FormLabel>
                  {t("workflowEditor.optionNumber", {
                    index: index + 1,
                  })}
                </FormLabel>
                <Input
                  placeholder={getMonthName(index, i18n.language)}
                  value={option.label}
                  onChange={(e) => {
                    setInput({
                      ...input,
                      options: input.options.map((o, i) => {
                        if (i === index) {
                          return {
                            ...o,
                            label: e.target.value,
                          };
                        }
                        return o;
                      }),
                    });
                  }}
                />
              </FormControl>
              <FormControl sx={{ flex: 1 }}>
                <FormLabel>{t("value")}</FormLabel>
                <Input
                  placeholder={(index % 12) + 1 + ""}
                  value={option.value}
                  onChange={(e) => {
                    setInput({
                      ...input,
                      options: input.options.map((o, i) => {
                        if (i === index) {
                          return {
                            ...o,
                            value: e.target.value,
                          };
                        }
                        return o;
                      }),
                    });
                  }}
                />
              </FormControl>

              <IconButton
                sx={{
                  border: 1,
                  borderColor: "lightgrey",
                  mb: 4,
                }}
                onClick={() => {
                  setInput({
                    ...input,
                    options: input.options.filter((o, i) => i !== index),
                  });
                }}
                disabled={input.options.length === 1}
              >
                <Delete />
              </IconButton>
            </Stack>
          </ListItem>
        ))}
      </List>

      <Button
        sx={{ mx: 0.5 }}
        variant="outlined"
        onClick={() => {
          setInput({
            ...input,
            options: [
              ...input.options,
              {
                label: "",
                value: "",
              },
            ],
          });
        }}
      >
        <div className="flex flex-row items-center gap-2">
          <Add />
          <span>{t("workflowEditor.addOption")}</span>
        </div>
      </Button>
    </Stack>
  );
}
