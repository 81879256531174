import {
  Dropdown,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
} from "@mui/joy";
import { Card, Typography } from "@mui/joy";
import type { Module } from "../../../../../backend/src/api/organization/modules/modulesTypes";
import * as Icons from "@mui/icons-material";
import { trpc } from "../../../lib/api/trpc/trpc";
import { DynamicIcon } from "../../util/DynamicIcon";
import { useTranslation } from "react-i18next";

export function ModuleGrid({ modules }: { modules?: Module[] }) {
  const user = trpc.user.me.useQuery().data;
  const utils = trpc.useUtils();
  const { t } = useTranslation();
  const toggleMutation = trpc.modules.toggleModule.useMutation();
  const deleteMutation = trpc.modules.deleteModule.useMutation();
  const toggleModule = async (module) => {
    await toggleMutation.mutateAsync(module);
    void utils.modules.invalidate();
  };
  const deleteModule = async (moduleId) => {
    if (confirm("Are you sure you want to delete?")) {
      await deleteMutation.mutateAsync({ moduleId });
      void utils.modules.invalidate();
    }
  };
  const getPrice = (price: string) => {
    let priceText = "";
    const priceNumber = price.replace("m", "");
    if (priceNumber !== "") {
      priceText += t("priceInEuro", { price: priceNumber });
      if (price.includes("m")) {
        priceText += t("monthly");
      }
    }
    return priceText;
  };

  return (
    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">
      {modules?.map((module) => (
        <Card
          key={module.title}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            minHeight: `150px`,
          }}
        >
          <div>
            <div className="flex flex-row items-center gap-3 pb-2">
              <DynamicIcon iconName={module.icon} />
              <Typography level="h4">{module.title}</Typography>
            </div>
            <Typography level="body-md" color="neutral" pb={2}>
              {module.description}
            </Typography>
            <Typography
              level="body-sm"
              color="neutral"
              textAlign="right"
              position="absolute"
              right={10}
              bottom={8}
              pt={10}
            >
              {getPrice(module.price ?? "")}
            </Typography>
          </div>
          {user?.isGlobalAdmin && (
            <Dropdown>
              <MenuButton
                startDecorator={<Icons.AdminPanelSettings />}
                size="sm"
              >
                Edit
              </MenuButton>
              <Menu size="sm">
                <MenuItem
                  onClick={() => {
                    void toggleModule(module);
                  }}
                >
                  <ListItemDecorator>
                    {module.activated ? (
                      <Icons.ToggleOff />
                    ) : (
                      <Icons.ToggleOn />
                    )}
                  </ListItemDecorator>
                  {module.activated ? "Deactivate" : "Activate"}
                </MenuItem>
                <ListDivider />
                <MenuItem
                  color="danger"
                  onClick={() => {
                    void deleteModule(module.id);
                  }}
                >
                  <ListItemDecorator>
                    <Icons.Delete />
                  </ListItemDecorator>
                  Delete
                </MenuItem>
              </Menu>
            </Dropdown>
          )}
        </Card>
      ))}
    </div>
  );
}
