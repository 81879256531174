import { Accordion, AccordionDetails, AccordionSummary } from "@mui/joy";
import { useTranslation } from "../../lib/i18n.ts";

export function ImageGenerationStep({
  currentStep,
  thisStep,
  setStep,
  title,
  children,
  isLast,
  selectable = true,
}: {
  currentStep: number;
  thisStep: number;
  setStep: (step: number) => void;
  title: string;
  children: React.ReactNode;
  isLast?: boolean;
  selectable?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Accordion
      disabled={!selectable}
      expanded={currentStep == thisStep}
      onChange={(_, newState) => {
        if (newState) {
          setStep(thisStep);
        } else {
          !isLast && setStep(thisStep + 1);
        }
      }}
      sx={isLast ? { borderBottom: "none" } : {}}
    >
      <AccordionSummary sx={{ fontWeight: "bold" }}>
        {t("step")} {thisStep}: {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
