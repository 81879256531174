import { Button, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { trpc } from "../../../lib/api/trpc/trpc";
import * as Icons from "@mui/icons-material";
import { useState } from "react";
import { ModuleGrid } from "./ModuleGrid";
import { ModuleCreation } from "./ModuleCreation";
import { SettingsPage } from "../SettingsPage";

export function ModulesDisplay() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const modules = trpc.modules.get.useQuery().data;
  const user = trpc.user.me.useQuery().data;
  console.log(modules);

  return (
    <SettingsPage
      title={t("settings.tabs.modules")}
      actions={
        user?.isGlobalAdmin && (
          <Button
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
            startDecorator={<Icons.AdminPanelSettings />}
          >
            Create
          </Button>
        )
      }
      subtitle={t("settings.modules.subtitle")}
    >
      <Modal open={modalOpen}>
        <ModalDialog sx={{ width: "75%", maxWidth: "1500px" }}>
          <ModalClose
            onClick={() => {
              setModalOpen(false);
            }}
          />
          <ModuleCreation
            onClose={() => {
              setModalOpen(false);
            }}
          />
        </ModalDialog>
      </Modal>
      <Typography level="h4">{t("settings.modules.enabledModules")}</Typography>
      <ModuleGrid modules={modules?.filter((m) => m.activated)} />
      <div>
        <Typography level="h4">
          {t("settings.modules.furtherExtensions")}
        </Typography>
        <Typography color="neutral">
          {t("settings.modules.contactSupport")}
        </Typography>
      </div>
      <ModuleGrid modules={modules?.filter((m) => !m.activated)} />
    </SettingsPage>
  );
}
