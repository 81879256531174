import { useTranslation } from "react-i18next";
import { imageStyles, type ImageStyle } from "./styles";
import { Card, Typography } from "@mui/joy";

export function StyleAccordion({
  selectedStyle,
  setSelectedStyle,
  onNext,
}: {
  selectedStyle: ImageStyle | null;
  setSelectedStyle: (style: ImageStyle) => void;
  onNext: () => void;
}) {
  return (
    <div className="grid grid-cols-1 gap-4 p-10 xl:grid-cols-2 2xl:grid-cols-3">
      {imageStyles.map((style) => (
        <StyleCard
          key={style.nameKey}
          style={style}
          selected={selectedStyle === style}
          onClick={() => {
            setSelectedStyle(style);
            onNext();
          }}
        />
      ))}
    </div>
  );
}

function StyleCard({
  style,
  selected,
  onClick,
}: {
  style: ImageStyle;
  selected: boolean;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="my-3 flex flex-col items-center gap-2">
      <Card
        onClick={onClick}
        className="cursor-pointer transition-all hover:shadow-lg"
        variant={selected ? "solid" : "outlined"}
        color={selected ? "primary" : "neutral"}
        sx={{
          width: 250,
          height: 250,
        }}
      >
        {typeof style.example === "string" ? (
          <img src={style.example} alt={style.nameKey} />
        ) : (
          style.example
        )}
      </Card>
      <Typography level="title-lg">{t(style.nameKey)}</Typography>
    </div>
  );
}
