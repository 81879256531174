import type { ImageRequest } from "../../../../backend/src/ai/images/imagesTypes.ts";
import type { DallE3ImageSize } from "../../../../backend/src/ai/images/providers/azureDalleTypes.ts";
import type { FluxAspectRatio } from "../../../../backend/src/ai/images/providers/replicateFluxTypes.ts";
import { SegmentDisplay } from "../input/SegmentDisplay.tsx";
import { useTranslation } from "../../lib/i18n.ts";

export function SizeSelector({
  input,
  setInput,
}: {
  input: ImageRequest;
  setInput: (input: ImageRequest) => void;
}) {
  const { t } = useTranslation();
  if (input.model === "dall-e-3") {
    return (
      <SegmentDisplay<DallE3ImageSize>
        size="md"
        buttonFlex={1}
        options={[
          {
            label: t("square"),
            value: "1024x1024",
          },
          {
            label: t("images.wide"),
            value: "1792x1024",
          },
          {
            label: t("images.high"),
            value: "1024x1792",
          },
        ]}
        value={input.size}
        setValue={(value) =>
          setInput({
            ...input,
            size: value,
          })
        }
      />
    );
  } else if (input.model === "flux-pro") {
    return (
      <SegmentDisplay<FluxAspectRatio>
        size="md"
        buttonFlex={1}
        options={[
          {
            label: t("square"),
            value: "1:1",
          },
          {
            label: t("images.wide"),
            value: "16:9",
          },
          {
            label: t("images.high"),
            value: "9:16",
          },
        ]}
        value={input.aspectRatio}
        setValue={(value) =>
          setInput({
            ...input,
            aspectRatio: value,
          })
        }
      />
    );
  } else {
    return null;
  }
}
