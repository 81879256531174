import { FormControl, FormLabel, Textarea, Typography } from "@mui/joy";
import { type WorkflowInput } from "apiTypes";
import { useTranslation } from "react-i18next";

export function ToggleEditor({
  input,
  setInput,
}: {
  input: WorkflowInput;
  setInput: (input: WorkflowInput) => void;
}) {
  const { t } = useTranslation();
  const onChange = (index: number, value: string) => {
    setInput({
      ...input,
      options: input.options.map((option, i) =>
        i === index ? { ...option, value } : option
      ),
    });
  };
  return (
    <div>
      <Typography level="title-lg" mb={4}>
        {t("workflowEditor.editVariable")}
      </Typography>
      <FormControl color="neutral" sx={{ mb: 3 }}>
        <FormLabel>{t("on")}</FormLabel>
        <Textarea
          value={input.options[0].value ?? ""}
          onChange={(e) => {
            onChange(0, e.target.value);
          }}
          sx={{ height: "100px" }}
        />
      </FormControl>

      <FormControl sx={{ mb: 3 }}>
        <FormLabel>{t("off")}</FormLabel>
        <Textarea
          value={input.options[1].value ?? ""}
          onChange={(e) => {
            onChange(1, e.target.value);
          }}
          sx={{ height: "100px" }}
        />
      </FormControl>
    </div>
  );
}
