import { AutoStories, Edit } from "@mui/icons-material";
import {
  Chip,
  CircularProgress,
  IconButton,
  ListDivider,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import type { DeeplyGlossaryLanguage } from "../../../../backend/src/api/tools/translateContent/deeplTranslator/deeplTranslatorLanguages.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";
import { useUser } from "../../lib/api/user.ts";

export interface ChosenGlossary {
  id: string;
  name: string;
  sourceLang: DeeplyGlossaryLanguage;
  targetLang: DeeplyGlossaryLanguage;
}

interface GlossarySelectProps {
  value: ChosenGlossary | null;
  onChange: (glossary: ChosenGlossary) => void;
  showModal: (
    variant: "create" | "edit" | null,
    glossary: ChosenGlossary | null
  ) => void;
}

const MAX_GLOSSARIES_PER_ORG = 5;

export default function GlossarySelect({
  value,
  onChange,
  showModal,
}: GlossarySelectProps) {
  const user = useUser("me");
  const { t } = useTranslation();

  const { data: glossaries, isLoading } =
    trpc.tools.translateContent.glossary.get.useQuery();

  const glossaryLimitReached =
    (glossaries?.length ?? 0) >= MAX_GLOSSARIES_PER_ORG;
  const canEditGlossaries = user?.isSuperAdmin || user?.isGlobalAdmin;

  return (
    <Select
      startDecorator={<AutoStories />}
      value={value?.id ?? ""}
      placeholder={t("tools.translateText.glossary.glossaries")}
      sx={{ px: 3, height: "50px", alignSelf: "center" }}
      renderValue={() => {
        return (
          <div className="flex items-center gap-2">
            <Typography>{value?.name}</Typography>
            <Chip
              variant="outlined"
              size="sm"
              sx={{ ml: "auto", textTransform: "capitalize" }}
            >
              {value?.sourceLang} - {value?.targetLang}
            </Chip>
          </div>
        );
      }}
    >
      {isLoading ? (
        <CircularProgress
          color="primary"
          size="sm"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        />
      ) : (
        glossaries?.map((glossary) => {
          const { id, name, sourceLang, targetLang } = glossary;
          return (
            <Option
              key={id}
              value={id}
              onClick={() => {
                onChange({
                  id,
                  name,
                  sourceLang: sourceLang as DeeplyGlossaryLanguage,
                  targetLang: targetLang as DeeplyGlossaryLanguage,
                });
              }}
            >
              <div className="flex w-full items-center justify-between gap-2">
                <Typography>{name}</Typography>
                <Chip
                  variant="outlined"
                  size="sm"
                  sx={{ ml: "auto", textTransform: "capitalize" }}
                >
                  {sourceLang} - {targetLang}
                </Chip>
                {canEditGlossaries && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      showModal("edit", glossary as ChosenGlossary);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
              </div>
            </Option>
          );
        })
      )}
      {!isLoading && glossaries?.length === 0 && (
        <Option disabled value="empty">
          {t("tools.translateText.glossary.noGlossaries")}
        </Option>
      )}

      {canEditGlossaries && (
        <>
          <ListDivider />
          <Option
            disabled={glossaryLimitReached}
            value="$create"
            onClick={() => showModal("create", null)}
          >
            {t(
              glossaryLimitReached
                ? "limitReached"
                : "tools.translateText.glossary.create"
            )}
          </Option>
        </>
      )}
    </Select>
  );
}
