import {
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Link,
  Typography,
} from "@mui/joy";
import type { Meeting } from "../../../../../../../backend/src/api/tools/meetingRecorder/meetingRecorderTypes.ts";
import { Link as RouterLink, useParams } from "../../../../../router.ts";
import { MeetingDate } from "./MeetingDate.tsx";
import { MeetingDuration } from "./meetingDuration.tsx";

export function MeetingCard({
  meeting,
}: {
  meeting: Meeting;
  onClick?: () => void;
}) {
  const { organizationId } = useParams("/:organizationId");

  return (
    <Card
      variant="outlined"
      className="h-full cursor-pointer"
      sx={{
        "&:hover": {
          boxShadow: "md",
          borderColor: "neutral.outlinedHoverBorder",
        },
      }}
    >
      <CardContent>
        <div className="mb-3 flex justify-between ">
          <Link
            overlay
            underline="none"
            component={RouterLink}
            to="/:organizationId/tools/meetingRecorder/:meetingId"
            params={{ organizationId, meetingId: meeting.id }}
          >
            <Typography level="h2" fontSize="md">
              {meeting.name}
            </Typography>
          </Link>
          <MeetingStatusChip status={meeting.status} />
        </div>
        {meeting.summary ? (
          <p className="line-clamp-3">{meeting.summary}</p>
        ) : (
          <Typography color="neutral" fontStyle="italic">
            Keine Zusammenfassung verfügbar
          </Typography>
        )}
      </CardContent>
      <CardActions sx={{ gap: 3 }}>
        <MeetingDate date={meeting.date} />
        <MeetingDuration duration={meeting.duration} />
      </CardActions>
    </Card>
  );
}

function MeetingStatusChip({ status }: { status: Meeting["status"] }) {
  switch (status) {
    case "DONE":
      return null;
    case "SUMMARY_GENERATION":
    case "TRANSCRIBING":
      return (
        <Chip
          color="primary"
          startDecorator={
            <CircularProgress
              sx={{
                mr: 0.5,
                "--CircularProgress-size": "16px",
                "--CircularProgress-trackThickness": "2px",
                "--CircularProgress-progressThickness": "2px",
              }}
            />
          }
        >
          {status === "TRANSCRIBING"
            ? "Wird verarbeitet"
            : "Protokoll wird erstellt"}
        </Chip>
      );
    case "FAILED":
      return <Chip color="danger">Fehlgeschlagen</Chip>;
  }
}
