import openaiIcon from "../assets/modelicons/openai.png";
import googleIcon from "../assets/modelicons/google.png";
import mistralIcon from "../assets/modelicons/mistral.png";
import perplexityIcon from "../assets/modelicons/perplexity.png";
import claudeIcon from "../assets/modelicons/claude.webp";
import MeinGPTLogo from "../../public/favicon.png";
import type { HTMLProps } from "react";

export function ModelIcon({
  modelName,
  ...props
}: {
  modelName: string;
} & HTMLProps<HTMLImageElement>) {
  let src: string | undefined;

  const model = modelName.toLowerCase();

  if (model.includes("llama")) {
    src = perplexityIcon;
  } else if (model.includes("gpt-")) {
    src = openaiIcon;
  } else if (model.includes("mistral")) {
    src = mistralIcon;
  } else if (model.includes("claude")) {
    src = claudeIcon;
  } else if (model.includes("gemini")) {
    src = googleIcon;
  } else if (model === "online-search-model") {
    src = MeinGPTLogo;
  } else {
    src = openaiIcon;
  }
  return <img src={src} alt={modelName + " icon"} {...props} />;
}
