import {
  ContentCopy,
  ContentPaste,
  Download,
  Upload,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  List,
  ListItem,
  ListItemContent,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCopyToClipboard } from "usehooks-ts";
import { WorkflowPortPackage } from "../../../../backend/src/api/workflow/workflowPort/workflowPortTypes";
import { useMutateDepartments } from "../../lib/api/department";
import { trpc } from "../../lib/api/trpc/trpc";
import { downloadBlob } from "../../lib/util";
import { SettingsPage } from "./SettingsPage";

export function WorkflowLibrary() {
  const { t } = useTranslation();
  const [subScreen, setSubScreen] = useState<"import" | "export">("export");

  return (
    <SettingsPage title={t("workflows")}>
      <ButtonGroup>
        <Button
          startDecorator={<Upload />}
          onClick={() => setSubScreen("export")}
          variant={subScreen === "export" ? "solid" : "outlined"}
          color={subScreen === "export" ? "primary" : "neutral"}
        >
          Export
        </Button>
        <Button
          startDecorator={<Download />}
          onClick={() => setSubScreen("import")}
          variant={subScreen === "import" ? "solid" : "outlined"}
          color={subScreen === "import" ? "primary" : "neutral"}
        >
          Import
        </Button>
      </ButtonGroup>
      {subScreen === "export" && <Export />}
      {subScreen === "import" && <Import />}
    </SettingsPage>
  );
}

function Export() {
  const [selectedWorkflows, setSelectedWorkflows] = useState<string[]>([]);
  const { data: exportableWorkflows } = trpc.workflows.port.list.useQuery();

  // Filter out selected workflows that are no longer exportable
  useEffect(() => {
    setSelectedWorkflows((e) =>
      e.filter((id) => exportableWorkflows?.find((w) => w.id === id))
    );
  }, [exportableWorkflows]);

  const { data: exportPackage, isFetching: packageGenerating } =
    trpc.workflows.port.export.useQuery({
      workflowIds: selectedWorkflows,
    });

  const [, copy] = useCopyToClipboard();

  return (
    <div>
      <Typography level="title-lg">Workflows zum Export auswählen</Typography>
      <div className="grid grid-cols-4 items-stretch gap-2 py-2">
        {exportableWorkflows?.map((workflow) => {
          const selected = selectedWorkflows.includes(workflow.id);
          return (
            <Card
              key={workflow.id}
              variant={selected ? "outlined" : "outlined"}
              color={selected ? "primary" : "neutral"}
              size="sm"
              onClick={() =>
                setSelectedWorkflows((e) =>
                  e.includes(workflow.id)
                    ? e.filter((id) => id !== workflow.id)
                    : [...e, workflow.id]
                )
              }
              className="cursor-pointer transition-shadow hover:shadow-lg"
            >
              <div className="flex flex-row gap-2">
                <Checkbox checked={selected}></Checkbox>
                <div className="flex flex-col">
                  <Typography level="title-sm">{workflow.name}</Typography>
                  <Typography level="body-sm">{workflow.department}</Typography>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
      <div className="mt-4 flex flex-col gap-2">
        <Typography>{selectedWorkflows.length} Workflows ausgewählt</Typography>
        <ButtonGroup
          disabled={packageGenerating || selectedWorkflows.length === 0}
        >
          <Button
            startDecorator={<ContentCopy />}
            onClick={() => {
              if (exportPackage) {
                void copy(JSON.stringify(exportPackage)).then(() => {
                  toast.success("In Zwischenablage kopiert");
                });
              }
            }}
            variant="solid"
            color="primary"
          >
            In Zwischenablage kopieren
          </Button>
          <Button
            startDecorator={<Download />}
            onClick={() => {
              if (exportPackage) {
                const jsonBlob = new Blob([JSON.stringify(exportPackage)], {
                  type: "application/json",
                });
                downloadBlob(jsonBlob, "workflows.json");
              }
            }}
            color="primary"
          >
            Herunterladen
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

function Import() {
  const [packedPackage, setPackedPackage] =
    useState<WorkflowPortPackage | null>(null);

  const { mutateAsync: importPackage } =
    trpc.workflows.port.import.useMutation();

  const tryRead = async (text: string) => {
    try {
      const data = JSON.parse(text);
      const p = WorkflowPortPackage.parse(data);
      setPackedPackage(p);
      toast.success("Paket erfolgreich eingelesen");
    } catch (e) {
      toast.error("Ungültiges Dateiformat");
    }
  };

  const utils = trpc.useUtils();
  const mutateDepartments = useMutateDepartments();

  return (
    <div className="flex flex-col gap-2">
      <Typography level="title-lg">Workflows importieren</Typography>
      <ButtonGroup color="primary">
        <Button
          startDecorator={<ContentPaste />}
          onClick={() => {
            void navigator.clipboard.readText().then(tryRead);
          }}
          variant="solid"
        >
          Aus Zwischenablage einfügen
        </Button>
        <Button
          startDecorator={<Upload />}
          onClick={() => {
            document.getElementById("file")?.click();
          }}
        >
          Aus Datei einfügen
        </Button>
      </ButtonGroup>
      <input
        hidden
        id="file"
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (!file) return;
          const reader = new FileReader();
          reader.onload = (e2) => {
            const text = e2.target?.result as string;
            void tryRead(text);
          };
          reader.readAsText(file);
        }}
      />
      {packedPackage && (
        <Modal
          open
          onClose={() => {
            setPackedPackage(null);
            toast.warning("Import abgebrochen");
          }}
        >
          <ModalDialog>
            <ModalClose />
            <Typography level="title-lg">Importieren</Typography>
            <Typography>
              {packedPackage.workflows.length} Workflows aus &quot;
              {packedPackage.originalOrganizationName}&quot; exportiert von
              &quot;{packedPackage.exportedBy}&quot; am{" "}
              {new Date(packedPackage.exportedAt).toLocaleString()} können
              importiert werden:
            </Typography>
            <List marker="disc" sx={{ overflowX: "auto" }}>
              {packedPackage.workflows.map((workflow, i) => (
                <ListItem key={i}>
                  <ListItemContent>
                    {workflow.name} ({workflow.departmentName})
                  </ListItemContent>
                </ListItem>
              ))}
            </List>
            <Button
              onClick={async () => {
                await importPackage(packedPackage);
                toast.success("Import erfolgreich");
                setPackedPackage(null);
                void utils.invalidate();
                void mutateDepartments();
              }}
            >
              Importieren
            </Button>
          </ModalDialog>
        </Modal>
      )}
    </div>
  );
}
