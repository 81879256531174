import { Attachment, CropSquare, Send } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Chip,
  Divider,
  IconButton,
  Switch,
  Textarea,
  Typography,
} from "@mui/joy";
import type { ComponentProps, PropsWithChildren } from "react";
import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { create } from "zustand";
import { useUploadDocumentWithToast } from "../../lib/api/documents";
import { useTranslation } from "../../lib/i18n";
import { DocumentChip } from "./attachments/DocumentChip.tsx";
import { allowedMimeTypesForAdiDocuments } from "../../lib/constants/mime.ts";
import { trpc } from "../../lib/api/trpc/trpc.ts";
import type { LlmName } from "../../../../backend/src/ai/llmMeta.ts";
import { WarningMessage } from "./WarningMessage.tsx";

export interface AttachedDocument {
  id: string;
  tokens: number;
}

export const useQueuedPlaceholderStore = create<{
  placeholder: string | null;
  queuePlaceholder: (placeholder: string) => void;
  clearPlaceholder: () => void;
}>((set) => ({
  placeholder: null,
  queuePlaceholder: (placeholder) => set({ placeholder }),
  clearPlaceholder: () => set({ placeholder: null }),
}));

export const ChatInput = React.forwardRef(
  (
    {
      ragMode,
      setRagMode,
      postMessage,
      startDecorator,
      disabled,
      onCancel,
      isGenerating,
      allowDocumentUpload = true,
      showAttachmentButton = true,
      embedded = false,
      value,
      setValue,
      model,
      setModelOverride,
      chatTokens,
      ...textAreaProps
    }: {
      ragMode: boolean;
      setRagMode: (ragMode: boolean) => void;
      postMessage: (
        message: string,
        attachmentIds: string[],
        ragMode: boolean
      ) => void;
      onCancel?: () => void;
      setModelOverride?: (model: LlmName) => void;
      isGenerating?: boolean;
      showAttachmentButton?: boolean;
      startDecorator?: React.ReactNode;
      allowDocumentUpload?: boolean;
      embedded?: boolean;
      value?: string;
      setValue?: (value: string) => void;
      model?: LlmName | null;
      chatTokens?: number;
    } & ComponentProps<typeof Textarea>,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    const organization = trpc.organization.getOrganization.useQuery().data;
    const [_input, _setInput] = useState("");
    const input = value ?? _input;
    const setInput = setValue ?? _setInput;
    const { placeholder, clearPlaceholder } = useQueuedPlaceholderStore();

    const [inlineSettingsExpanded, setInlineSettingsExpanded] = useState(false);

    const [messageCreditWarningAccepted, setMessageCreditWarningAccepted] =
      useState(false);

    const [attachedDocuments, setAttachedDocuments] = useState<
      AttachedDocument[]
    >([]);

    const attachedDocumentIds = attachedDocuments?.map((d) => d.id) ?? [];

    useEffect(() => {
      if (placeholder != null && placeholder.length > 0) {
        setInput(placeholder);
        clearPlaceholder();
      }
    }, [placeholder, clearPlaceholder, setInput]);

    const send = () => {
      if (input.replace(/\na/g, "").trim() === "") return;

      postMessage(input.trim(), attachedDocumentIds, ragMode);
      setInlineSettingsExpanded(false);
      setInput("");
      setAttachedDocuments([]);
      setMessageCreditWarningAccepted(false);
    };

    const attachmentUploaderRef = useRef<HTMLInputElement>(null);

    const [numLoadingAttachments, setNumLoadingAttachments] = useState(0);

    const hasAttachedDocuments =
      attachedDocumentIds.length > 0 || numLoadingAttachments > 0;

    const uploadDocument = useUploadDocumentWithToast();

    const onAddAttachment = async () => {
      const files = attachmentUploaderRef.current?.files;
      if (!files || files.length === 0) return;

      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (!file) continue;
        setNumLoadingAttachments((prev) => prev + 1);
        uploadDocument(file)
          .then((doc) => {
            setAttachedDocuments((prev) => [
              ...prev,
              { id: doc.id, tokens: doc.tokens },
            ]);
          })
          .catch(console.error)
          .finally(() => {
            setNumLoadingAttachments((prev) => prev - 1);
          });

        setInlineSettingsExpanded(false);
        // clear the input
      }
      attachmentUploaderRef.current.value = "";
    };

    const { t } = useTranslation();

    const isDisabled = disabled || numLoadingAttachments > 0;

    const { data: availableKnowledgeCollections } =
      trpc.rag.knowledgeCollections.getAll.useQuery();
    const { data: productConfig } = trpc.productConfig.get.useQuery();
    const { data: documentIntelligenceEnabled } =
      trpc.tools.documentIntelligence.isEnabled.useQuery();

    const fileUploadEnabled =
      documentIntelligenceEnabled &&
      allowDocumentUpload &&
      showAttachmentButton;
    const ragEnabled =
      availableKnowledgeCollections &&
      availableKnowledgeCollections.length &&
      productConfig?.enableRag;

    const chosenModel = model ?? organization?.defaultModel;

    return (
      <>
        <div
          className={twMerge(
            "grid grid-cols-[40px_1fr_100px] grid-rows-[auto_1fr] gap-x-4",
            embedded && "gap"
          )}
          id="messageInput"
        >
          {!embedded && chosenModel && setModelOverride && (
            <WarningMessage
              model={chosenModel}
              setModelOverride={setModelOverride}
              attachedDocuments={attachedDocuments}
              input={input}
              chatTokens={chatTokens}
              messageCreditWarningAccepted={messageCreditWarningAccepted}
              setMessageCreditWarningAccepted={setMessageCreditWarningAccepted}
            />
          )}
          {startDecorator}
          <input
            type="file"
            name="file"
            id="attachment"
            accept={allowedMimeTypesForAdiDocuments}
            hidden
            ref={attachmentUploaderRef}
            onChange={onAddAttachment}
            multiple
          />
          <div className="relative col-start-2 row-start-2 flex w-full flex-col items-center">
            <Textarea
              className="z-10 w-full flex-1"
              sx={{
                display: "flex",
                flexDirection: hasAttachedDocuments ? "column" : "row",
                alignItems: hasAttachedDocuments ? "start" : "center",
              }}
              slotProps={{
                textarea: {
                  ref,
                  sx: {
                    paddingY: embedded ? "5px" : "20px",
                  },
                },
                startDecorator: {
                  sx: {
                    paddingX: "0px",
                    margin: "0px",
                    marginRight: "10px",
                    height: "100%",
                  },
                },
              }}
              placeholder={t("composeMessage")}
              autoFocus
              maxRows={18}
              minRows={1}
              onKeyDown={(e) => {
                // submit the form on control+enter
                if (input.replace(/\na/g, "").trim() === "") return;

                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (!isDisabled) send();
                }
              }}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              {...textAreaProps}
              startDecorator={
                <div className="flex flex-row flex-wrap items-center gap-2">
                  {setAttachedDocuments &&
                    attachedDocuments &&
                    attachedDocumentIds.map((id) => (
                      <DocumentChip
                        documentId={id}
                        key={id}
                        onRemove={() => {
                          setAttachedDocuments(
                            attachedDocuments.filter((d) => d.id !== id)
                          );
                        }}
                      />
                    ))}
                  {numLoadingAttachments > 0 &&
                    new Array(numLoadingAttachments)
                      .fill({})
                      .map((_, i) => <DocumentChip key={i} loading />)}
                  {!!(fileUploadEnabled || ragEnabled) && (
                    <>
                      <InlineSettings
                        expanded={inlineSettingsExpanded}
                        setExpanded={setInlineSettingsExpanded}
                      >
                        {!!fileUploadEnabled && (
                          <Button
                            onClick={() => {
                              attachmentUploaderRef.current?.click();
                            }}
                            variant="plain"
                            size="sm"
                            startDecorator={<Attachment fontSize="medium" />}
                            className=" whitespace-nowrap"
                            disabled={ragMode}
                            sx={{ mr: 1 }}
                          >
                            {t("uploadDocument")}
                          </Button>
                        )}
                        {!!ragEnabled && (
                          <Typography
                            className="whitespace-nowrap"
                            component="label"
                            level="body-sm"
                            endDecorator={
                              <Switch
                                size="sm"
                                sx={{ ml: 1 }}
                                checked={ragMode}
                                onChange={() => {
                                  setRagMode(!ragMode);
                                }}
                              />
                            }
                          >
                            <Chip size="sm" color="primary" sx={{ mr: 0.5 }}>
                              Beta
                            </Chip>
                            {t("ragMode")}
                          </Typography>
                        )}
                      </InlineSettings>
                      {!hasAttachedDocuments && (
                        <Divider orientation="vertical" />
                      )}
                    </>
                  )}
                </div>
              }
            ></Textarea>
          </div>
          {isGenerating && !!onCancel ? (
            <Button
              onClick={onCancel}
              variant="outlined"
              className="col-start-3 row-start-2 self-center"
            >
              <div className="flex flex-row items-center gap-2">
                <CropSquare fontSize="small" />
                {input.length > 0 ? t("resend") : t("cancel")}
              </div>
            </Button>
          ) : (
            <Button
              className="col-start-3 row-start-2 flex flex-row items-center gap-2 self-center"
              onClick={send}
              disabled={isDisabled}
            >
              <Send fontSize="small" />
              {!embedded && t("sendMessage")}
            </Button>
          )}
        </div>
      </>
    );
  }
);

ChatInput.displayName = "ChatInput";

function InlineSettings({
  children,
  expanded,
  setExpanded,
}: PropsWithChildren<{
  expanded: boolean;
  setExpanded: (e: boolean) => void;
}>) {
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="inline-flex items-center">
      <IconButton onClick={toggleExpand}>
        <AddIcon
          style={{
            transition: "all 200ms cubic-bezier(0.34, 1.56, 0.64, 1)",
            transform: expanded ? "rotate(135deg)" : "",
          }}
        />
      </IconButton>
      <div
        className="grid"
        style={{
          transition:
            "grid-template-columns 200ms cubic-bezier(0.34, 1.56, 0.64, 1)",
          gridTemplateColumns: expanded ? "1fr" : "0fr",
        }}
      >
        <div className="flex flex-row overflow-hidden">{children}</div>
      </div>
    </div>
  );
}
