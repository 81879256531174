import { Box, Button } from "@mui/joy";
import { useTranslation } from "../../lib/i18n";
import { useCallback, useEffect, useState } from "react";

import { DocumentDropzone } from "../chat/attachments/DocumentDropzone";
import { documentMimeType } from "../../lib/constants/mime";
import { trpc } from "../../lib/api/trpc/trpc";
import {
  base64ToBlob,
  downloadBlob,
  fileToBase64,
  splitByExtention,
  targetToSourceLanguage,
} from "../../lib/util";
import { LargeDocumentPreview } from "../chat/attachments/DocumentChip";
import { toast } from "react-toastify";
import type { SelectedLanguagesWithDetect } from "./TranslateTable";
import TranslateTable from "./TranslateTable";
import { Delete } from "@mui/icons-material";

// Sizes in bytes
const MAX_SIZE_LIMITS = {
  doc: 31_457_280, // 30MB
  docx: 31_457_280, // 30MB
  pptx: 31_457_280, // 30MB
  pdf: 31_457_280, // 30MB
  txt: 1_048_576, // 1MB
  html: 5_242_880, // 5MB
  htm: 5_242_880, // 5MB
  xlf: 10_485_760, // 10MB
  xliff: 10_485_760, // 10MB
  srt: 1_048_576, // 1MB
};

export default function DocumentPanel({
  selectedLanguages,
  setSelectedLanguages,
  glossaryId,
}: {
  selectedLanguages: SelectedLanguagesWithDetect;
  setSelectedLanguages: (languages: SelectedLanguagesWithDetect) => void;
  glossaryId?: string;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>();

  const { sourceLanguage, targetLanguage } = selectedLanguages;

  const { mutateAsync: uploadDocument } =
    trpc.tools.translateContent.documentTranslator.translate.useMutation();

  const checkSizeLimit = useCallback(
    (newFile: File) => {
      const { name, size } = newFile;
      const [, extension] = splitByExtention(name);
      const max = MAX_SIZE_LIMITS[extension];
      const valid = size < max;
      if (!valid)
        toast.error(t("errors.fileTooLarge", { size: max / (1024 * 1024) })); // convert bytes to MB

      return valid;
    },
    [t]
  );

  const { tooltip, title } = {
    tooltip: `${file?.name} (${file?.size})`,
    title: file?.name ?? "",
  };

  const handleTranslation = async () => {
    if (!file) return;
    setIsLoading(true);
    try {
      const { name, type } = file;
      const [filename, extension] = splitByExtention(name);
      const content = (await fileToBase64(file)).split(",")[1]; // split to remove metadata

      const {
        document: { data },
      } = await uploadDocument({
        content,
        extension,
        source_lang: sourceLanguage,
        target_lang: targetLanguage,
        glossaryId,
      });

      const blob = base64ToBlob(data, type);
      const cleanLanguage = targetToSourceLanguage(targetLanguage);
      downloadBlob(blob, `${filename}_${cleanLanguage}`);
    } catch (error) {
      console.error(error);
      toast.error(t("errors.docTranslateError"));
    } finally {
      setFile(undefined);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (file && !checkSizeLimit(file)) {
      setFile(undefined);
    }
  }, [file, checkSizeLimit]);

  const buttonVisible = file && !isLoading;

  return (
    <TranslateTable
      selectedLanguages={selectedLanguages}
      isLoading={isLoading}
      setSelectedLanguages={setSelectedLanguages}
    >
      <Box
        display="flex"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          height: "50vh",
          maxHeight: "600px",
        }}
      >
        <div className="flex h-full w-full flex-col items-center justify-center gap-10">
          {!file ? (
            <DocumentDropzone
              style={{
                border: 0,
                textAlign: "center",
              }}
              customMime={documentMimeType}
              allowMultiple={false}
              onSelect={(files) => setFile(files[0])}
            />
          ) : (
            <LargeDocumentPreview tooltip={tooltip} title={title} />
          )}
          {!isLoading && (
            <div className="flex flex-col items-center gap-4">
              {buttonVisible && (
                <Button
                  sx={{ maxWidth: "400px" }}
                  size="lg"
                  fullWidth
                  onClick={handleTranslation}
                >
                  {t("translate")}
                </Button>
              )}
              {file && (
                <Button
                  size="sm"
                  color="neutral"
                  variant="plain"
                  onClick={() => {
                    !isLoading && setFile(undefined);
                  }}
                  endDecorator={<Delete />}
                >
                  {t("remove")}
                </Button>
              )}
            </div>
          )}
        </div>
      </Box>
    </TranslateTable>
  );
}
