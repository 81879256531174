import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Typography,
} from "@mui/joy";
import type { TypographySystem } from "@mui/joy";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { z } from "zod";
import { useOrganization } from "../../lib/api/organization";
import { trpc } from "../../lib/api/trpc/trpc";
import { useMe } from "../../lib/api/user";
import { useTranslation } from "../../lib/i18n";
import { EnabledModelsSelector } from "../input/ModelSelectorModal";
import { DelayedLoader } from "../util/DelayadLoader";
import { PopoverPicker } from "./PopoverPicker";
import { HelpCenterInputs } from "./HelpCenterInputs";
import type { ContactInfo } from "../../../../backend/src/api/organization/contactInfo/contactInfoTypes";
import type { LlmName } from "../../../../backend/src/ai/llmMeta";
import { LLM_META } from "../../../../backend/src/ai/llmMeta";
import { useNavigate } from "react-router-dom";
import { SettingsPage } from "./SettingsPage";
import { SuperAdminSettings } from "./SuperAdminSettings";

const urlSettingsZod = (error: string) =>
  z
    .union([z.string().url(), z.literal("")], {
      invalid_type_error: error,
    })
    .transform((value) => (value === "" ? null : value));

export const Settings = ({
  name,
  children,
  level = "title-md",
}: {
  name: string;
  children: React.ReactNode;
  level?: keyof TypographySystem;
}) => {
  return (
    <tr className="h-4">
      <td className="w-1/4 py-3 align-top">
        <Typography level={level}>{name}</Typography>
      </td>
      <td className="py-3">{children}</td>
    </tr>
  );
};

export function GeneralSettings() {
  const { t } = useTranslation();
  const organization = useOrganization();
  const updateOrganization = trpc.organization.mutateOrganization.useMutation();
  const archiveOrganizationMutation = trpc.organization.archive.useMutation();
  const utils = trpc.useUtils();
  const navigate = useNavigate();

  const { data: enabledModels } = trpc.modelConfig.getEnabled.useQuery();
  const { data: activeModels } = trpc.modelConfig.getActive.useQuery();

  const { mutateAsync: enableModel } = trpc.modelConfig.enable.useMutation();
  const { mutateAsync: disableModel } = trpc.modelConfig.disable.useMutation();
  const user = useMe();

  const [orgColor, setOrgColor] = useState<string | null>(
    organization?.customPrimaryColor ?? null
  );

  const contactInfoQuery =
    trpc.contactInfo.getOrganizationContactInfo.useQuery().data;

  const contactInfo = {
    name: contactInfoQuery?.name ?? "",
    email: contactInfoQuery?.email ?? "",
    phone: contactInfoQuery?.phone ?? "",
    additionalInfo: contactInfoQuery?.additionalInfo ?? "",
  };
  const contactInfoMutation =
    trpc.contactInfo.mutateOrganizationContactInfo.useMutation();
  const onContactInfoBlur = (e, key: keyof ContactInfo) => {
    contactInfoMutation
      .mutateAsync({
        [key]: e.target.value,
      })
      .then(() => {
        void utils.contactInfo.getOrganizationContactInfo.invalidate();
        toast.success(t("contactsUpdated"));
      })
      .catch((error) => {
        console.error(error);
        toast.error(t("saveFailed"));
      });
  };

  const availableModels = (activeModels ?? []).filter(
    (m) => LLM_META[m as LlmName]?.allowChat
  ) as LlmName[];

  if (!organization) return <DelayedLoader />;

  const handleUpdateOrganization = async (
    value,
    name,
    dbKey,
    toastMessage?
  ) => {
    try {
      await updateOrganization
        .mutateAsync({ [dbKey]: value })
        .then(() => utils.organization.getOrganization.invalidate());
      toast.success(toastMessage ?? `${t(name)} ${t("settingsChange")}`);
    } catch (error) {
      console.error(error);
      toast.error(t("saveFailed"));
    }
  };

  /**
   * Wrapper for input settings, including event handling to reduce code
   * @param name the language file name reference for display, e.g. organizationTitle
   * @param dbKey the key of the setting in the database, e.g. domain; The value is organization[dbKey]
   * @param altValue an optional alternative value in case the value is not found
   * @param toastMessage the message to display in the toast when the setting is updated
   * @param type
   * @param disabledMessage
   */
  function OrganizationSettings({
    name,
    dbKey,
    altValue = "",
    toastMessage,
    type = "text",
    disabledMessage,
    validation,
  }: {
    name: string;
    dbKey: string;
    altValue?: string;
    toastMessage?: string;
    type?: string;
    disabledMessage?: string;
    tooltipMessage?: string;
    validation: z.ZodType;
  }) {
    return (
      <Settings name={t(name)}>
        {disabledMessage ? (
          <Typography>{disabledMessage}</Typography>
        ) : (
          <Input
            type={type}
            defaultValue={organization![dbKey] ?? altValue}
            onBlur={(event) => {
              if (event.target.value === (organization![dbKey] ?? "")) return; // No change, and all falsy values are considered equal
              const parsed = validation.safeParse(event.target.value);

              if (parsed.success) {
                handleUpdateOrganization(
                  parsed.data,
                  name,
                  dbKey,
                  toastMessage
                ).catch((error) => {
                  console.error(error);
                  toast.error(t("saveFailed"));
                });
              } else {
                event.target.value = organization![dbKey] ?? altValue;
                toast.error(parsed.error.format()._errors.join(", "));
              }
            }}
          />
        )}
      </Settings>
    );
  }

  async function handleOrgColorUpdate(color: string | null) {
    //Check if the color is a valid hex color
    if (color === null || /^#[0-9A-F]{6}$/i.test(color)) {
      await handleUpdateOrganization(
        color,
        t("organizationColor"),
        "customPrimaryColor"
      );
      setOrgColor(null);
    }
  }

  /**
   * @returns If the model was actually updated
   */
  async function handleUpdateModel(
    modelKey: string,
    enabled: boolean
  ): Promise<boolean> {
    if (enabled) {
      await enableModel({
        model: modelKey,
      });
      void utils.invalidate();
      return true;
    } else {
      if ((enabledModels?.length ?? 0) <= 1) {
        toast.error(t("cantDisableLastModel"));
        return false;
      }
      if (organization!.defaultModel === modelKey) {
        toast.error(t("cantDisableDefaultModel"));
        return false;
      }
      await disableModel({
        model: modelKey,
      });
      void utils.invalidate();
      return true;
    }
  }
  const archiveOrganization = () => {
    const res = prompt(
      `Please enter the organization name to confirm (${organization.name})`
    );
    if (res === organization.name) {
      archiveOrganizationMutation
        .mutateAsync()
        .then(() => {
          void utils.organization.invalidate();
        })
        .catch(() => {
          alert("Failed to archive organization");
        });
      navigate("/");
    } else if (res !== null) {
      alert("Organization name does not match");
    }
  };
  return (
    <SettingsPage title={t("generalSettings")}>
      {user?.isGlobalAdmin && (
        <SuperAdminSettings>
          <table className="!mb-8 table-auto">
            <tbody>
              <OrganizationSettings
                name="organizationName"
                dbKey="name"
                validation={z
                  .string()
                  .min(1, t("errors.nameCannotBeEmpty"))
                  .max(100, t("errors.nameTooLong"))}
              />
              <Settings name={t("organizationColor")}>
                <Input
                  startDecorator={
                    <PopoverPicker
                      color={organization.customPrimaryColor!}
                      onChange={async (color) => {
                        setOrgColor(color);
                        await handleUpdateOrganization(
                          color,
                          t("organizationColor"),
                          "customPrimaryColor"
                        );
                      }}
                    />
                  }
                  endDecorator={
                    orgColor !== null ? (
                      <IconButton onClick={() => handleOrgColorUpdate(null)}>
                        <ClearIcon />
                      </IconButton>
                    ) : null
                  }
                  value={orgColor ?? ""}
                  onChange={(e) => setOrgColor(e.target.value)}
                  onBlur={(e) => handleOrgColorUpdate(e.target.value)}
                />
              </Settings>
              <OrganizationSettings
                name="organizationAvatarUrl"
                dbKey="avatarUrl"
                validation={urlSettingsZod(t("errors.invalidUrl"))}
              />
              <OrganizationSettings
                name="organizationHeaderUrl"
                dbKey="logoUrl"
                validation={urlSettingsZod(t("errors.invalidUrl"))}
              />
            </tbody>
          </table>
          <Typography level="title-lg">{t("supportContact")}</Typography>
          <Typography color="neutral">{t("supportContactInfo")}</Typography>
          <HelpCenterInputs
            contactInfo={contactInfo}
            onBlur={onContactInfoBlur}
          />
        </SuperAdminSettings>
      )}

      <Typography level="title-lg">{t("enabledLLMs")}</Typography>

      <FormControl key="nonEuWarningSkippable">
        <Checkbox
          label={t("removeNonEuDontShow")}
          checked={organization?.nonEuWarningSkippable ?? false}
          onChange={async (e) => {
            await handleUpdateOrganization(
              e.target.checked,
              t("removeNonEuDontShow"),
              "nonEuWarningSkippable"
            );
          }}
        />
        <FormHelperText>{t("removeNonEuDontShowHelperText")}</FormHelperText>
      </FormControl>
      <EnabledModelsSelector
        availableModels={availableModels}
        selectedModels={enabledModels ?? []}
        updateModel={handleUpdateModel}
        setDefaultModel={async (model) => {
          await updateOrganization.mutateAsync({
            defaultModel: model,
          });
          void utils.invalidate();
          toast.success(t("changeDefaultLLMConfirm"));
        }}
      />
      {user?.isGlobalAdmin && (
        <div className="mt-10">
          <Typography level="title-lg" mb={2}>
            Danger Zone
          </Typography>
          <SuperAdminSettings color="danger">
            <div className="flex items-center justify-between">
              <Typography level="title-md">
                Archive the organization and all its data
              </Typography>
              <Button onClick={archiveOrganization} color="danger">
                Archive organization
              </Button>
            </div>
          </SuperAdminSettings>
        </div>
      )}
    </SettingsPage>
  );
}
