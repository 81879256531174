import { Typography } from "@mui/joy";
import type { DallEInputRequest } from "../../../../backend/src/ai/images/providers/azureDalleTypes.ts";
import { SegmentDisplay } from "../input/SegmentDisplay.tsx";
import { useTranslation } from "../../lib/i18n.ts";

export function DallEOptions({
  input,
  setInput,
}: {
  input: DallEInputRequest;
  setInput: (input: DallEInputRequest) => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Typography>{t("images.quality")}</Typography>
      <SegmentDisplay<DallEInputRequest["quality"]>
        buttonFlex={1}
        size="md"
        options={[
          {
            label: "Standard",
            value: "standard",
          },
          {
            label: "HD",
            value: "hd",
          },
        ]}
        value={input.quality}
        setValue={(value) =>
          setInput({
            ...input,
            quality: value,
          })
        }
      />
      <Typography>{t("images.genStyle")}</Typography>
      <SegmentDisplay<DallEInputRequest["style"]>
        buttonFlex={1}
        size="md"
        options={[
          {
            label: "Vivid",
            value: "vivid",
          },
          {
            label: "Natural",
            value: "natural",
          },
        ]}
        value={input.style}
        setValue={(value) =>
          setInput({
            ...input,
            style: value,
          })
        }
      />
    </>
  );
}
