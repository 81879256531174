import type { CompletionRequest } from "apiTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { compilePrompt, prompts } from "../prompts";
import { useCompletion } from "../../api/completions";

const languageNames = {
  de: "German (Deutsch)",
  en: "English",
  fr: "French (Francais)",
  it: "Italian (Italiano)",
  es: "Spanish (Espagnol)",
};

function useEvaluatePrompt() {
  const completion = useCompletion();
  const { i18n } = useTranslation();
  return useCallback(
    async (prompt: string) => {
      const criteriaPrompts = [
        prompts.promptingAssistant.promptAssumptions,
        prompts.promptingAssistant.promptTask,
        prompts.promptingAssistant.promptOutput,
      ];
      console.log("criteriaPrompts", criteriaPrompts);
      const compiledPrompts = criteriaPrompts.map((criteriaPrompt) =>
        compilePrompt(criteriaPrompt, {
          prompt,
          language:
            languageNames[i18n.resolvedLanguage ?? "de"] ?? languageNames["de"],
        })
      );
      const responses = await Promise.all(
        compiledPrompts.map((compiledPrompt) =>
          completion(
            {
              messages: compiledPrompt,
              response: "json_object",
            } as CompletionRequest,
            "gpt-4o"
          )
        )
      );
      return responses.map(
        (response) =>
          JSON.parse(response.message) as { rating: string; comment: string }
      );
    },
    [completion, i18n.resolvedLanguage]
  );
}

export function getSummaryForRating(rating: string) {
  return {
    "1": "Das war leider nicht so gut...",
    "2": "Gut, aber das geht noch besser!",
    "3": "Super!",
  }[rating];
}

export function usePromptingAssistant() {
  const [promptEvaluations, setPromptEvaluations] = useState<
    { rating: string; comment: string }[]
  >([]);

  const [loading, setLoading] = useState(false);
  const evaluatePrompt = useEvaluatePrompt();

  const evaluate = useCallback(
    async (prompt: string) => {
      setLoading(true);
      let response: Awaited<ReturnType<typeof evaluatePrompt>> | null = null;
      let tries = 0;
      while (!response && tries < 3) {
        try {
          tries++;
          response = await evaluatePrompt(prompt);
        } catch (e) {
          console.error(e);
          console.log("retrying...");
        }
      }

      if (!response) {
        throw new Error("Failed to evaluate prompt");
      }

      setPromptEvaluations(response);
      setLoading(false);
    },
    [evaluatePrompt]
  );

  const shortSummaries = promptEvaluations.map((evaluation) =>
    getSummaryForRating(evaluation?.rating || "")
  );

  return {
    promptEvaluations,
    loading,
    evaluate,
    shortSummaries,
    resetEvaluation: () => setPromptEvaluations([]),
  };
}
