import z from 'zod';

export const DallE3ImageSize = z.union([
  z.literal('1024x1024'),
  z.literal('1792x1024'),
  z.literal('1024x1792'),
]);
export type DallE3ImageSize = z.infer<typeof DallE3ImageSize>;

export const DallEInputRequest = z.object({
  model: z.literal('dall-e-3'),
  numberOfImages: z.number(),
  prompt: z.string(),
  quality: z
    .union([z.literal('standard'), z.literal('hd')])
    .default('standard'),
  size: DallE3ImageSize.default('1024x1024'),
  style: z.union([z.literal('vivid'), z.literal('natural')]).default('vivid'),
});
export type DallEInputRequest = z.infer<typeof DallEInputRequest>;
