import z from 'zod';

export const FluxAspectRatio = z.enum(['1:1', '16:9', '9:16']);
export type FluxAspectRatio = z.infer<typeof FluxAspectRatio>;

export const FluxImageRequest = z.object({
  model: z.literal('flux-pro'),
  numberOfImages: z.number(),
  prompt: z.string(),
  aspectRatio: FluxAspectRatio,
});
export type FluxImageRequest = z.infer<typeof FluxImageRequest>;
