import { CopyAll } from "@mui/icons-material";
import { Textarea, IconButton } from "@mui/joy";
import { Stack, Box } from "@mui/system";
import { trpc } from "../../lib/api/trpc/trpc";
import TranslateTable, {
  type SelectedLanguagesWithDetect,
} from "./TranslateTable";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useCopySafe } from "../../lib/hooks/useCopySafe";

export function TextPanel({
  glossaryId,
  selectedLanguages,
  setSelectedLanguages,
}: {
  glossaryId?: string;
  selectedLanguages: SelectedLanguagesWithDetect;
  setSelectedLanguages: (languages: SelectedLanguagesWithDetect) => void;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [initRequest, setInitRequest] = useState<boolean>(true);
  const [sourceText, setSourceText] = useState("");
  const [targetText, setTargetText] = useState("");
  const { sourceLanguage, targetLanguage } = selectedLanguages;
  const translationMutation =
    trpc.tools.translateContent.textTranslator.translate.useMutation();
  const copy = useCopySafe();

  const loadingTimout = useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));

  const debouncedTranslate = useDebouncedCallback((source) => {
    if (!source) return;

    // If the response takes too long, after 500ms show the loading spinner
    clearTimeout(loadingTimout.current);
    loadingTimout.current = setTimeout(() => {
      setLoading(true);
    }, 500);

    void translationMutation
      .mutateAsync({
        text: source,
        source_lang: sourceLanguage,
        target_lang: targetLanguage,
        track: initRequest,
        glossaryId,
      })
      .then(() => {
        clearTimeout(loadingTimout.current);
        setLoading(false);
      });
    setInitRequest(false);
  }, 500);

  const translate = async (source: string) => {
    setSourceText(source);
    debouncedTranslate(source);
  };

  //updates the translation text
  useEffect(() => {
    if (translationMutation.data) {
      setTargetText(translationMutation.data.text);
    } else if (translationMutation.isError) {
      setTargetText(t("errors.couldNotTranslateText"));
    }
  }, [t, translationMutation.data, translationMutation.isError, setTargetText]);

  useEffect(() => {
    void translate(sourceText);
  }, [sourceLanguage, targetLanguage, sourceText]);

  const copyText = () => {
    copy(targetText);
  };

  return (
    <TranslateTable
      setSelectedLanguages={setSelectedLanguages}
      isLoading={loading}
      selectedLanguages={selectedLanguages}
    >
      <Stack direction="row" width="100%">
        <Textarea
          sx={{
            p: 2,
            pr: sourceLanguage === "ar" ? 2 : 0,
            pl: sourceLanguage === "ar" ? 0 : 2,
            border: 0,
            borderRadius: "0 0 0 10px",
            borderRight: "1px solid #CED4D9",
            width: "50%",
            height: "50vh",
            maxHeight: "600px",
            direction: sourceLanguage === "ar" ? "rtl" : "ltr",
            overflow: "hidden",
            textarea: {
              overflowY: "auto !important",
            },
          }}
          value={sourceText}
          placeholder={t("enterText")}
          onChange={(e) => {
            void translate(e.target.value);
          }}
        />
        <Box
          sx={{
            p: 2,
            border: 0,
            borderRadius: "0 0 10px 0",
            width: "50%",
            height: "50vh",
            maxHeight: "600px",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            overflowY: "auto",
            direction: targetLanguage === "ar" ? "rtl" : "ltr",
          }}
        >
          <div
            // Ensure that line breaks are preserved when copy pasting to word
            // Safe, because the target text is the translated input text from the user themselves
            dangerouslySetInnerHTML={{
              __html: targetText.replace(/\n/g, "<br />"),
            }}
          />
          <IconButton
            onClick={copyText}
            sx={{ position: "absolute", bottom: 20, right: 35 }}
            aria-label="Copy translation"
          >
            <CopyAll />
          </IconButton>
        </Box>
      </Stack>
    </TranslateTable>
  );
}
