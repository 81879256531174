import z from 'zod';
import { FluxImageRequest } from './providers/replicateFluxTypes';
import { DallEInputRequest } from './providers/azureDalleTypes';

export const ImageResponse = z.object({
  urls: z.string().array(),
  price: z.number(),
});
export type ImageResponse = z.infer<typeof ImageResponse>;

export const ImageRequest = z.discriminatedUnion('model', [
  FluxImageRequest,
  DallEInputRequest,
]);

export type ImageRequest = z.infer<typeof ImageRequest>;

export type ImageModel = ImageRequest['model'];

export const DefaultImageRequest: Record<ImageModel, ImageRequest> = {
  'flux-pro': {
    model: 'flux-pro',
    numberOfImages: 1,
    prompt: '',
    aspectRatio: '1:1',
  },
  'dall-e-3': {
    model: 'dall-e-3',
    numberOfImages: 1,
    prompt: '',
    quality: 'standard',
    size: '1024x1024',
    style: 'vivid',
  },
};
