import {
  Chip,
  Sheet,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import { DepartmentsEditor } from "../../../components/settings/deparments/DepartmentsEditor.tsx";
import { GeneralSettings } from "../../../components/settings/GeneralSettings.tsx";
import RAGSettings from "../../../components/settings/rag/RAGSettings.tsx";
import { UsersEditor } from "../../../components/settings/users/UsersEditor.tsx";
import { useTranslation } from "../../../lib/i18n.ts";
import { InvitesEditor } from "../../../components/settings/users/InvitesEditor.tsx";
import { ModulesDisplay } from "../../../components/settings/modules/ModulesDisplay.tsx";
import { CreditDisplay } from "../../../components/settings/CreditDisplay.tsx";
import React, { useMemo } from "react";
import { useSearchParamsState } from "../../../lib/hooks/useSearchParamsState.ts";
import { useMe } from "../../../lib/api/user.ts";
import { EnableAcademy } from "../../../components/settings/EnableAcademy.tsx";
import { ProductConfigEditor } from "../../../components/settings/ProductConfigEditor.tsx";
import { trpc } from "../../../lib/api/trpc/trpc.ts";
import { AdminPanelSettings } from "@mui/icons-material";
import { WorkflowLibrary } from "../../../components/settings/WorkflowLibrary.tsx";

export default function OrganisationSettings() {
  const [activeTab, setActiveTab] = useSearchParamsState("tab", "general");
  const { t } = useTranslation();
  const { data: productConfig } = trpc.productConfig.get.useQuery();
  const user = useMe();

  const settingsTabs = useMemo(() => {
    const tabs: {
      label: string;
      component: React.ReactNode;
      path: string;
      beta?: boolean;
      superAdminOnly?: boolean;
    }[] = [
      {
        label: t("settings.tabs.general"),
        component: <GeneralSettings />,
        path: "general",
      },
      {
        label: t("settings.tabs.departments"),
        component: <DepartmentsEditor />,
        path: "departments",
      },
      {
        label: t("settings.tabs.users"),
        component: <UsersEditor />,
        path: "users",
      },
      {
        label: t("settings.tabs.invites"),
        component: <InvitesEditor />,
        path: "invites",
      },
      {
        label: t("settings.tabs.modules"),
        component: <ModulesDisplay />,
        path: "modules",
      },
      {
        label: "Academy",
        component: <EnableAcademy />,
        path: "academy",
        superAdminOnly: true,
      },
      {
        label: "Workflows",
        component: <WorkflowLibrary />,
        path: "workflows",
        superAdminOnly: true,
      },
      {
        label: "Product Config",
        component: <ProductConfigEditor />,
        path: "product-config",
        superAdminOnly: true,
      },
    ].filter((tab) => !tab.superAdminOnly || user?.isSuperAdmin);
    if (productConfig?.creditSystem) {
      tabs.push({
        label: t("settings.tabs.credits"),
        component: <CreditDisplay />,
        path: "credits",
      });
    }
    if (productConfig?.enableRag) {
      tabs.push({
        label: t("knowledgeBase.title"),
        component: <RAGSettings />,
        path: "rag",
        beta: true,
      });
    }

    return tabs;
  }, [t, user, productConfig]);

  return (
    <Sheet className="min-h-screen">
      <div className="flex flex-col items-center px-10 pt-10">
        <div className="container flex flex-col gap-10">
          <Typography level="h2">{t("settings.literal")}</Typography>
          <Tabs
            sx={{
              minWidth: 600,
              height: "100%",
              backgroundColor: "transparent",
            }}
            size="sm"
            value={Math.max(
              settingsTabs.findIndex((tab) => tab.path === activeTab),
              0
            )}
            onChange={(_, newValue) =>
              setActiveTab(
                settingsTabs.find((tab, i) => i === newValue)?.path ?? "general"
              )
            }
          >
            <TabList>
              {settingsTabs.map((tab, i) => (
                <Tab
                  key={tab.label}
                  sx={{
                    px: 6,
                    py: 1,
                    whiteSpace: "nowrap",
                  }}
                  value={i}
                >
                  {tab.beta && (
                    <Chip
                      size="sm"
                      color="primary"
                      variant="solid"
                      sx={{ mr: 0.5 }}
                    >
                      Beta
                    </Chip>
                  )}
                  {tab.superAdminOnly && (
                    <AdminPanelSettings
                      color="primary"
                      sx={{ fontSize: "22px" }}
                    />
                  )}
                  {tab.label}
                </Tab>
              ))}
            </TabList>
            {settingsTabs.map((tab, i) => (
              <TabPanel key={tab.label} value={i} className="pb-20">
                <div className="h-full pt-5">{tab.component}</div>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </Sheet>
  );
}
