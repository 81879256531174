import { Close, Info, Warning } from "@mui/icons-material";
import {
  AccordionGroup,
  Alert,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  ModalOverflow,
  Sheet,
  Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import {
  DefaultImageRequest,
  type ImageModel,
  type ImageRequest,
} from "../../../../../backend/src/ai/images/imagesTypes";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer";
import { GeneratedImage } from "../../../components/image/GeneratedImage.tsx";
import { ImageModelSelector } from "../../../components/image/ImageModelSelector";
import { ImagePromptInputEditor } from "../../../components/image/ImagePromptInputEditor.tsx";
import { StyleAccordion } from "../../../components/image/ImageStyleCard";
import type { ImageStyle } from "../../../components/image/styles";

import { DelayedLoader } from "../../../components/util/DelayadLoader";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useClosableInfoModal } from "../../../lib/context/infoModalStore";
import { useTranslation } from "../../../lib/i18n";
import { ImageGenerationStep } from "../../../components/image/ImageGenerationStep.tsx";

export { ErrorDisplay as Catch } from "../../../components/util/ErrorDisplay";

export default function ImageFactory() {
  const { t } = useTranslation();

  const [selectedModel, setSelectedModel] = useState<ImageModel | null>(null);

  const [imageInput, setImageInput] = useState<ImageRequest>(
    DefaultImageRequest[selectedModel ?? "flux-pro"]
  );
  useEffect(
    () => setImageInput(DefaultImageRequest[selectedModel ?? "flux-pro"]),
    [selectedModel]
  );

  const { data: availableModels } = trpc.tools.images.listConfigured.useQuery();

  const moreInfoModal = useClosableInfoModal("imageFactory-tutorial");

  const [selectedStyle, setSelectedStyle] = useState<ImageStyle | null>(null);

  const {
    mutateAsync: generateImage,
    isPending: generationPending,
    data: generatedImages,
    error: generationError,
  } = trpc.tools.images.generateImages.useMutation({
    trpc: {
      context: {
        silentError: true,
      },
    },
  });

  const onGenerate = () => {
    if (!selectedModel) return;

    const promptTemplate = (selectedStyle?.promptSuffix ?? "").includes(
      "{{prompt}}"
    )
      ? selectedStyle!.promptSuffix
      : "{{prompt}}" + selectedStyle?.promptSuffix;

    const modelInput = {
      ...imageInput,
      prompt: promptTemplate.replace("{{prompt}}", imageInput.prompt),
    };

    void generateImage({
      model: selectedModel,
      input: modelInput,
    });
  };

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!selectedModel && step > 1) {
      setStep(1);
    }
  }, [selectedModel, step]);

  let stepIter = 0;

  if (!availableModels) return <DelayedLoader />;

  return (
    <Sheet variant="soft" className="min-h-screen">
      <div className="flex flex-col items-center justify-center gap-10 p-20">
        <Typography level="h1">{t("generateImage")} </Typography>
        <AccordionGroup size="lg" variant="plain" transition="0.4s ease-in-out">
          {availableModels?.length > 1 && (
            <ImageGenerationStep
              currentStep={step}
              thisStep={++stepIter}
              setStep={setStep}
              title={
                selectedModel
                  ? t("images.model.titleSelected", {
                      name: t("images.model." + selectedModel),
                    })
                  : t("images.model.title")
              }
            >
              <ImageModelSelector
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                onNext={() => setStep((s) => ++s)}
              />
            </ImageGenerationStep>
          )}
          <ImageGenerationStep
            currentStep={step}
            thisStep={++stepIter}
            setStep={setStep}
            title={
              selectedStyle
                ? t("images.style.selectedstyle", {
                    name: t(selectedStyle.nameKey),
                  })
                : t("images.style.selectstyle")
            }
            selectable={!!selectedModel}
          >
            <StyleAccordion
              selectedStyle={selectedStyle}
              setSelectedStyle={setSelectedStyle}
              onNext={() => setStep((s) => ++s)}
            />
          </ImageGenerationStep>
          <ImageGenerationStep
            currentStep={step}
            thisStep={++stepIter}
            setStep={setStep}
            title={t("images.prompt")}
            isLast
            selectable={!!selectedStyle}
          >
            <Card>
              {moreInfoModal.modalOpen && (
                <div className="flex flex-col gap-4 p-5">
                  <Alert
                    startDecorator={<Info />}
                    endDecorator={
                      <IconButton>
                        <Close onClick={() => moreInfoModal.onModalClose()} />
                      </IconButton>
                    }
                    color="primary"
                    className="whitespace-pre"
                    sx={{
                      whiteSpace: "normal",
                    }}
                  >
                    {t("images.promptInfo")}
                    <MoreInfoModal />
                  </Alert>
                </div>
              )}
              <ImagePromptInputEditor
                input={imageInput}
                setInput={setImageInput}
                onGenerate={onGenerate}
              />
            </Card>
          </ImageGenerationStep>
        </AccordionGroup>
        {generationPending && (
          <>
            <Typography level="body-lg">
              {t("images.generating", {
                count: imageInput.numberOfImages ?? 1,
              })}
              ...
            </Typography>
            <CircularProgress size="lg" />
          </>
        )}
        {generatedImages && !generationPending && (
          <>
            <div className="flex flex-col gap-4 2xl:flex-row">
              {generatedImages.urls.map((image) => (
                <GeneratedImage url={image} key={image} />
              ))}
            </div>
            <Alert startDecorator={<Info />} color="primary">
              {t("images.historyHint")}
            </Alert>
          </>
        )}
        {generationError && !generationPending && !generatedImages && (
          <Alert color="danger" startDecorator={<Warning />}>
            {generationError.message}
          </Alert>
        )}
      </div>
    </Sheet>
  );
}

function MoreInfoModal() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="plain">
        {t("moreInfo")}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalOverflow>
          <ModalDialog>
            <ModalClose />
            <Typography level="h1">{t("images.tutorial.title")}</Typography>
            <MarkdownRenderer
              content={t("images.tutorial.body")}
            ></MarkdownRenderer>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
