import {
  Clear,
  Delete,
  DriveFolderUpload,
  Edit,
  Language,
  MoreVert,
  Sync,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { toast } from "react-toastify";
import type { DataConnector } from "../../../../../../backend/src/api/rag/dataConnector/dataConnectorTypes.ts";
import { trpc } from "../../../../lib/api/trpc/trpc.ts";
import { useMe } from "../../../../lib/api/user.ts";
import { readableFileSize } from "../../../../lib/util.ts";
import { DataSourceIcon } from "./AddSourceModal.tsx";
import { ConnectorEditModal } from "./ConnectorEditModal.tsx";
import { FileUploadConnectorModal } from "./FileUploadConnectorModal.tsx";

export function ConnectorCard({
  connector,
  onRemove,
}: {
  connector: DataConnector;
  onRemove: VoidFunction;
}) {
  const me = useMe();

  const utils = trpc.useUtils();

  const { mutateAsync: triggerSync, isPending: syncPending } =
    trpc.rag.dataConnectors.admin.custom.triggerSync.useMutation();

  const { mutateAsync: editDataConnector } =
    trpc.rag.dataConnectors.admin.uploads.updateMeta.useMutation();

  const { mutateAsync: deleteConnector } =
    trpc.rag.dataConnectors.admin.delete.useMutation();

  const { data: fileInfo, isFetched: fileInfoAvailable } =
    trpc.rag.dataConnectors.admin.uploads.listFiles.useQuery(
      {
        dataConnectorId: connector.id,
      },
      { enabled: connector.connectorType === "MANUAL_UPLOAD" }
    );

  const numFiles = fileInfo?.length ?? 0;
  const fileSize = fileInfo?.reduce((acc, val) => acc + val.size, 0) ?? 0;

  const [editModalOpen, setEditModalOpen] = useState(false);

  async function confirmAndDeleteConnector() {
    if (!confirm('Ordner "' + connector.name + '" löschen?')) {
      return;
    }

    try {
      await deleteConnector({
        dataConnectorId: connector.id,
      });
      await utils.rag.dataConnectors.admin.getAll.invalidate();
      toast.success("Quelle wurde gelöscht");
    } catch (e) {
      toast.error("Quelle konnte nicht gelöscht werden");
    }
  }

  async function confirmAndRemoveFromPool() {
    if (
      !confirm('Quelle "' + connector.name + '" aus dem Datenpool entfernen?')
    ) {
      return;
    }
    onRemove();
  }

  return (
    <Card className="relative">
      <div className="absolute right-0 top-0 p-2 transition-all">
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{ root: { variant: "plain", color: "neutral" } }}
          >
            <MoreVert />
          </MenuButton>
          <Menu placement="bottom-end">
            <MenuItem
              disabled={"CUSTOM" == connector.connectorType}
              onClick={() => {
                setEditModalOpen(true);
              }}
            >
              <ListItemDecorator>
                <Edit />
              </ListItemDecorator>
              Bearbeiten
            </MenuItem>
            {"CUSTOM" == connector.connectorType ? (
              <MenuItem color="danger" onClick={confirmAndRemoveFromPool}>
                <ListItemDecorator>
                  <Clear />
                </ListItemDecorator>
                Entfernen
              </MenuItem>
            ) : (
              <MenuItem color="danger" onClick={confirmAndDeleteConnector}>
                <ListItemDecorator sx={{ color: "inherit" }}>
                  <Delete />
                </ListItemDecorator>
                Löschen
              </MenuItem>
            )}
          </Menu>
        </Dropdown>
      </div>

      <div className="flex items-center gap-3">
        <Avatar size="lg" className="row-span-2">
          {
            {
              CUSTOM: <DataSourceIcon icon={connector.customIcon} />,
              WEB_SCRAPER: <Language />,
              MANUAL_UPLOAD: <DriveFolderUpload />,
            }[connector.connectorType]
          }
        </Avatar>
        <div className="flex flex-col gap-1">
          <Typography level="title-md">{connector.name}</Typography>
          <div className="flex justify-center gap-2">
            {connector.connectorType === "MANUAL_UPLOAD" &&
              (fileInfoAvailable ? (
                <>
                  <Typography level="body-sm">
                    {numFiles + " Datei" + (numFiles === 1 ? "" : "en")}
                  </Typography>
                  ·
                  <Typography level="body-sm">
                    {readableFileSize(fileSize)}
                  </Typography>
                </>
              ) : (
                <>
                  <Skeleton level="body-sm" variant="text" />
                  <Skeleton level="body-sm" variant="text" />
                </>
              ))}
          </div>
        </div>
      </div>
      <Typography level="body-md" mb={2}>
        {connector.description}
      </Typography>

      {connector.connectorType === "WEB_SCRAPER" && (
        <Typography level="body-sm" textAlign="center" color="primary">
          {connector.settings?.targetUrl}
        </Typography>
      )}
      <div className="flex grow items-end justify-start">
        {connector.connectorType === "MANUAL_UPLOAD" && (
          <FileUploadConnectorModal id={connector.id} />
        )}
        {connector.connectorType === "CUSTOM" && me?.isGlobalAdmin && (
          <Button
            variant="soft"
            loading={syncPending}
            onClick={() => {
              void triggerSync({
                dataConnectorId: connector.id,
              }).then((res) => {
                toast.success(
                  `Synchronisierung von ${res.fileCount} Dateien gestartet.`
                );
              });
            }}
            startDecorator={<Sync />}
          >
            Trigger Sync
          </Button>
        )}
      </div>

      <ConnectorEditModal
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
        }}
        name={connector?.name ?? ""}
        description={connector?.description ?? ""}
        onChange={(name, description) =>
          editDataConnector({
            dataConnectorId: connector.id,
            name,
            description: description ?? null,
          }).then(() => utils.rag.dataConnectors.admin.invalidate())
        }
      />
    </Card>
  );
}
