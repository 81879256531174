import { ImportExport } from "@mui/icons-material";
import { Box, IconButton } from "@mui/joy";
import { targetToSourceLanguage, sourceToTargetLanguage } from "../../lib/util";
import { LanguagePicker } from "../input/LanguagePicker";
import { type SelectedLanguagesWithDetect } from "./TranslateTable";
import {
  DEEPL_LANGUAGES_SOURCE,
  DEEPL_LANGUAGES_TARGET,
  type DeepLLanguage,
  type DeepLSourceLanguage,
  type DeepLTargetLanguage,
} from "../../../../backend/src/api/tools/translateContent/deeplTranslator/deeplTranslatorLanguages";

export function LanguagesPicker({
  selectedLanguages,
  setSelectedLanguages,
  detectLanguage,
  languagesOverride,
}: {
  selectedLanguages: SelectedLanguagesWithDetect;
  setSelectedLanguages: (languages: SelectedLanguagesWithDetect) => void;
  detectLanguage?: boolean;
  languagesOverride?: string[];
}) {
  const { sourceLanguage, targetLanguage } = selectedLanguages;
  const switchPossible = sourceLanguage !== "detect";
  const replaceLanguage = "de";

  const duplicateCheck = (language: DeepLLanguage) => {
    if (sourceLanguage === "detect") return false;
    return (
      language.includes(targetLanguage) || language.includes(sourceLanguage)
    );
  };

  const handleSourceChange = (language: DeepLSourceLanguage) => {
    let newTarget: DeepLTargetLanguage | undefined;
    if (duplicateCheck(language)) {
      newTarget = targetLanguage == replaceLanguage ? "en-GB" : replaceLanguage;
    }

    setSelectedLanguages({
      targetLanguage: newTarget || targetLanguage,
      sourceLanguage: language,
    });
  };
  const handleTargetChange = (language: DeepLTargetLanguage) => {
    let newSource: DeepLSourceLanguage | undefined;
    if (duplicateCheck(language)) {
      newSource = sourceLanguage == replaceLanguage ? "en" : replaceLanguage;
    }

    setSelectedLanguages({
      sourceLanguage: newSource || sourceLanguage,
      targetLanguage: language,
    });
  };
  return (
    <Box sx={{ justifyContent: "space-between" }} display="flex">
      <Box sx={{ flex: 1 }}>
        <LanguagePicker
          selectedLanguage={sourceLanguage}
          languages={
            languagesOverride ??
            (detectLanguage
              ? ["detect", ...DEEPL_LANGUAGES_SOURCE]
              : [...DEEPL_LANGUAGES_SOURCE])
          }
          onSelect={(language) => {
            handleSourceChange(language as DeepLSourceLanguage);
          }}
        />
      </Box>
      <IconButton
        sx={{ mx: 4, opacity: switchPossible ? 1 : 0.5 }}
        disabled={!switchPossible}
        onClick={() => {
          setSelectedLanguages({
            sourceLanguage: targetToSourceLanguage(targetLanguage),
            targetLanguage: sourceToTargetLanguage(sourceLanguage),
          });
        }}
      >
        <ImportExport sx={{ transform: "rotate(90deg)" }} />
      </IconButton>

      <Box sx={{ flex: 1 }}>
        <LanguagePicker
          selectedLanguage={targetLanguage}
          languages={languagesOverride ?? [...DEEPL_LANGUAGES_TARGET]}
          onSelect={(language) => {
            handleTargetChange(language as DeepLTargetLanguage);
          }}
        />
      </Box>
    </Box>
  );
}
