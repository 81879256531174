import { z } from "zod";
import { Workflow } from "./Workflow";

export const ApiEntraIdGroup = z.object({
  id: z.string(),
  displayName: z.string(),
});

export type ApiEntraIdGroup = z.infer<typeof ApiEntraIdGroup>;

const ContactInfo = z
  .object({
    name: z.string().nullable(),
    email: z.union([z.string().email().nullable(), z.literal("")]),
    phone: z.union([
      z
        .string()
        .refine((val) => /^\+?\d+$/.test(val) || val === "", {
          message: "Invalid phone number",
        })
        .nullable(),
      z.literal(""),
    ]),
    additionalInfo: z.string().nullable(),
  })
  .partial();

export const ApiDepartment = z.object({
  id: z.string(),
  name: z.string(),
  writePermission: z.boolean(),
  default: z.boolean(),
  userCount: z.number(),
  isPersonal: z.boolean(),
  workflows: Workflow.array(),
  entraGroups: ApiEntraIdGroup.array().optional(),
  contactInfo: ContactInfo.optional(),
});

export type ApiDepartment = z.infer<typeof ApiDepartment>;

export const CreateApiDepartment = ApiDepartment.partial().omit({
  id: true,
  workflows: true,
  contactInfo: true,
});

export const PatchApiDepartment = z.object({
  name: z.string(),
  default: z.boolean(),
  entraGroupsIds: z.array(z.string()).nullable(),
  contactInfo: ContactInfo.optional(),
});
export type PatchApiDepartment = z.infer<typeof PatchApiDepartment>;

export const ApiDepartmentUser = z.object({
  userId: z.string(),
  writePermission: z.boolean(),
});

export const PatchApiDepartmentUser = ApiDepartmentUser.partial().omit({
  userId: true,
});

export type ApiDepartmentUser = z.infer<typeof ApiDepartmentUser>;
export type PatchApiDepartmentUser = z.infer<typeof PatchApiDepartmentUser>;
