import { Card, Chip, Typography } from "@mui/joy";

import bflIcon from "../../assets/modelicons/bfl.png";
import openaiIcon from "../../assets/modelicons/openai.png";
import type { ImageModel } from "../../../../backend/src/ai/images/imagesTypes";
import { DelayedLoader } from "../util/DelayadLoader";
import { trpc } from "../../lib/api/trpc/trpc";
import { useTranslation } from "react-i18next";

export function ImageModelSelector({
  selectedModel,
  setSelectedModel,
  onNext,
}: {
  selectedModel: ImageModel | null;
  setSelectedModel: (model: ImageModel) => void;
  onNext: () => void;
}) {
  const enabledModels = trpc.tools.images.listConfigured.useQuery().data;
  if (!enabledModels) return <DelayedLoader />;
  return (
    <div className="grid grid-cols-1 gap-4 p-10 xl:grid-cols-2 2xl:grid-cols-3">
      {(enabledModels as ImageModel[]).map((model) => (
        <ModelCard
          key={model}
          model={model}
          selected={selectedModel === model}
          onClick={() => {
            setSelectedModel(model);
            onNext();
          }}
        />
      ))}
    </div>
  );
}

function ModelCard({
  model,
  selected,
  onClick,
}: {
  model: ImageModel;
  selected: boolean;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="my-3 flex flex-col items-center gap-2">
      <Card
        onClick={onClick}
        className="relative cursor-pointer transition-all hover:shadow-lg"
        variant={selected ? "outlined" : "outlined"}
        color={selected ? "primary" : "neutral"}
        sx={{
          width: 250,
          height: 250,
        }}
      >
        <img
          src={
            {
              "flux-pro": bflIcon,
              "dall-e-3": openaiIcon,
            }[model]
          }
          alt={t("images.model." + model)}
          className="h-full w-full p-10"
        />
        {model == "flux-pro" && (
          <div className="absolute right-0 top-0 flex flex-row gap-2 p-2">
            <Chip color="warning">🔥 {t("new")}</Chip>
            <Chip color="primary">{t("recommended")}</Chip>
          </div>
        )}
      </Card>
      <Typography level="title-lg" color={selected ? "primary" : "neutral"}>
        {t("images.model." + model)}
      </Typography>
    </div>
  );
}
