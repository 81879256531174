import { Add, Delete } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Table,
  Typography,
} from "@mui/joy";
import { skipToken } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  DEEPL_GLOSSARY_LANGUAGE,
  type DeeplyGlossaryLanguage,
} from "../../../../backend/src/api/tools/translateContent/deeplTranslator/deeplTranslatorLanguages";
import { trpc } from "../../lib/api/trpc/trpc";
import type { ChosenGlossary } from "./GlossarySelect.tsx";
import { LanguagesPicker } from "./LanguagesPicker";
import type { SelectedLanguagesWithDetect } from "./TranslateTable";

const MAX_ENTRIES_LIMIT = 5_000;

export type GlossaryModalVariant = "create" | "edit" | null;

interface GlossaryModalProps {
  variant: GlossaryModalVariant;
  initGlossary: ChosenGlossary | null;
  closeModal: () => void;
}

interface GlossaryLanguages {
  sourceLanguage: DeeplyGlossaryLanguage;
  targetLanguage: DeeplyGlossaryLanguage;
}

export function GlossaryModal({
  variant,
  initGlossary,
  closeModal,
}: GlossaryModalProps) {
  const [originalWord, setOriginalWord] = useState<string>("");
  const [translation, setTranslation] = useState<string>("");
  const [selectedLanguages, setSelectedLanguages] = useState<GlossaryLanguages>(
    {
      sourceLanguage: initGlossary?.sourceLang ?? "en",
      targetLanguage: initGlossary?.targetLang ?? "de",
    }
  );

  const originalWordRef = useRef<HTMLInputElement>(null);

  const [glossaryNameInput, setGlossaryNameInput] = useState(
    variant === "edit" ? initGlossary?.name ?? "" : ""
  );
  const [glossaryWords, setGlossaryWords] = useState<Record<string, string>>(
    {}
  );

  const { t } = useTranslation();

  const utils = trpc.useUtils();
  const glossaryEntries =
    trpc.tools.translateContent.glossary.getEntries.useQuery(
      initGlossary && variant === "edit"
        ? { glossaryId: initGlossary.id }
        : skipToken
    ).data;

  const { mutateAsync: createGlossaryMutation, isPending: createLoading } =
    trpc.tools.translateContent.glossary.create.useMutation({
      trpc: {
        context: {
          silentError: true,
        },
      },
    });

  const { mutateAsync: updateGlossaryMutation, isPending: updateLoading } =
    trpc.tools.translateContent.glossary.update.useMutation({
      trpc: {
        context: {
          silentError: true,
        },
      },
    });

  const { mutateAsync: deleteGlossaryMutation, isPending: deleteLoading } =
    trpc.tools.translateContent.glossary.delete.useMutation({
      trpc: {
        context: {
          silentError: true,
        },
      },
    });

  const isLoading = createLoading || updateLoading || deleteLoading;

  useEffect(() => {
    if (glossaryEntries) {
      setGlossaryWords(glossaryEntries);
    }
  }, [glossaryEntries]);

  if (!variant) return null;

  const resetState = () => {
    setSelectedLanguages({
      sourceLanguage: "en",
      targetLanguage: "de",
    });
    setGlossaryNameInput("");
    setGlossaryWords({});
  };

  const createGlossary = () => {
    createGlossaryMutation({
      name: glossaryNameInput?.trim(),
      source_lang: selectedLanguages.sourceLanguage,
      target_lang: selectedLanguages.targetLanguage,
      entries: glossaryWords,
    })
      .then(() => {
        toast.success(t("tools.translateText.glossary.created"));
        void utils.tools.translateContent.glossary.invalidate();
        resetState();
        closeModal();
      })
      .catch(() => {
        toast.error(t("errors.couldNotCreateGlossary"));
      });
  };

  const updateGlossary = () => {
    updateGlossaryMutation({
      glossaryId: initGlossary?.id ?? "",
      name: glossaryNameInput,
      source_lang: selectedLanguages.sourceLanguage,
      target_lang: selectedLanguages.targetLanguage,
      entries: glossaryWords,
    })
      .then((res) => {
        toast.success(t("tools.translateText.glossary.updated"));
        void utils.tools.translateContent.glossary.get.invalidate();
        if (localStorage.getItem("chosenGlossaryId") === initGlossary?.id) {
          localStorage.setItem("chosenGlossaryId", res.glossaryId);
        }
        resetState();
        closeModal();
      })
      .catch(() => {
        toast.error(t("errors.couldNotUpdateGlossary"));
      });
  };

  const deleteGlossary = () => {
    if (confirm(t("tools.translateText.glossary.deleteConfirmation"))) {
      deleteGlossaryMutation({ glossaryId: initGlossary?.id ?? "" })
        .then(() => {
          toast.success(t("tools.translateText.glossary.deleted"));
          void utils.tools.translateContent.glossary.invalidate();
          resetState();
          closeModal();
        })
        .catch(() => {
          toast.error(t("errors.couldNotDeleteGlossary"));
        });
    }
  };

  const limitReached = Object.keys(glossaryWords).length >= MAX_ENTRIES_LIMIT;

  const addDisabled =
    isLoading || originalWord === "" || translation === "" || limitReached;

  function addWord() {
    if (!addDisabled) {
      setOriginalWord("");
      setTranslation("");
      setGlossaryWords((prev) => ({
        ...prev,
        [originalWord.trim()]: translation.trim(),
      }));
      originalWordRef.current?.focus();
    }
  }

  const createGlossaryDisabled =
    !glossaryNameInput || Object.keys(glossaryWords).length === 0;

  return (
    <div className="flex h-full max-h-[90vh] min-h-[100px] grow flex-col gap-10">
      {variant === "edit" && !glossaryEntries ? (
        <div className="flex w-full justify-center">
          <CircularProgress size="lg" />
        </div>
      ) : (
        <>
          {/* Header Section */}
          <div className="flex items-center gap-2">
            <Typography level="h4">
              {t("tools.translateText.glossary." + variant)}
            </Typography>
            {variant === "edit" && (
              <IconButton
                onClick={deleteGlossary}
                sx={{
                  "&:hover": {
                    color: "red",
                  },
                }}
              >
                <Delete />
              </IconButton>
            )}
          </div>

          {/* Languages Picker */}
          <div>
            <FormLabel sx={{ mb: 1 }}>
              {t("tools.translateText.glossary.languages")}
            </FormLabel>
            <LanguagesPicker
              languagesOverride={[...DEEPL_GLOSSARY_LANGUAGE]}
              selectedLanguages={
                selectedLanguages as SelectedLanguagesWithDetect
              }
              setSelectedLanguages={(languages) =>
                setSelectedLanguages({
                  sourceLanguage:
                    languages.sourceLanguage as DeeplyGlossaryLanguage,
                  targetLanguage:
                    languages.targetLanguage as DeeplyGlossaryLanguage,
                })
              }
            />
          </div>

          {/* Glossary Name Input */}
          <FormControl>
            <FormLabel>
              {t("tools.translateText.glossary.name")}
              <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Input
              value={glossaryNameInput}
              onChange={(e) => setGlossaryNameInput(e.target.value)}
            />
          </FormControl>

          {/* Table Section */}
          <div className="flex grow flex-col">
            <div className=" overflow-y-auto" style={{ maxHeight: "500px" }}>
              <Table className="w-full table-fixed">
                <thead className="sticky top-0 z-10">
                  <tr>
                    <th style={{ width: "40%" }}>{t("originalWord")}</th>
                    <th style={{ width: "40%" }}>{t("translation")}</th>
                    <th style={{ width: "10%" }} />
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(glossaryWords).length === 0 ? (
                    <tr>
                      <td colSpan={3}>
                        {t("tools.translateText.glossary.noWords")}
                      </td>
                    </tr>
                  ) : (
                    Object.keys(glossaryWords).map((k) => (
                      <tr key={k}>
                        <td>{k}</td>
                        <td>{glossaryWords[k]}</td>
                        <td>
                          <IconButton
                            onClick={() => {
                              setGlossaryWords((prev) => {
                                const updated = { ...prev };
                                delete updated[k];
                                return updated;
                              });
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot className="sticky bottom-0 z-10">
                  <tr>
                    <td>
                      <Input
                        slotProps={{ input: { ref: originalWordRef } }}
                        value={originalWord}
                        onChange={(e) => {
                          setOriginalWord(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            addWord();
                          }
                        }}
                        disabled={limitReached}
                        placeholder={limitReached ? t("limitReached") : ""}
                      />
                    </td>
                    <td>
                      <Input
                        value={translation}
                        onChange={(e) => setTranslation(e.target.value)}
                        disabled={limitReached}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            addWord();
                          }
                        }}
                      />
                    </td>
                    <td>
                      <IconButton
                        disabled={addDisabled}
                        className="self-end"
                        color="primary"
                        variant="soft"
                        onClick={addWord}
                      >
                        <Add />
                      </IconButton>
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>

          {/* Footer Button */}
          <Button
            fullWidth
            onClick={variant === "create" ? createGlossary : updateGlossary}
            disabled={createGlossaryDisabled}
          >
            {t(variant === "create" ? "create" : "save")}
          </Button>
        </>
      )}
    </div>
  );
}
