import { Box, Card, LinearProgress, Link, Stack, Typography } from "@mui/joy";

import {
  type DeepLSourceLanguage,
  type DeepLTargetLanguage,
} from "../../../../backend/src/api/tools/translateContent/deeplTranslator/deeplTranslatorLanguages";
import { LanguagesPicker } from "./LanguagesPicker";

export interface SelectedLanguagesWithDetect {
  sourceLanguage: DeepLSourceLanguage | "detect";
  targetLanguage: DeepLTargetLanguage;
}

export interface SelectedLanguages {
  sourceLanguage: DeepLSourceLanguage;
  targetLanguage: DeepLTargetLanguage;
}

interface TranslateTableProps {
  children: React.ReactNode;
  selectedLanguages: SelectedLanguagesWithDetect;
  setSelectedLanguages: (
    selectedLanguages: SelectedLanguagesWithDetect
  ) => void;
  isLoading: boolean;
}

export default function TranslateTable({
  children,
  isLoading,
  selectedLanguages,
  setSelectedLanguages,
}: TranslateTableProps) {
  return (
    <Stack width="100%" position="relative">
      <Box className="w-full">
        <Card>
          <Stack height="100%">
            <Stack borderBottom="1px solid #CED4D9" pb={1}>
              <LanguagesPicker
                selectedLanguages={selectedLanguages}
                setSelectedLanguages={setSelectedLanguages}
                detectLanguage
              />
            </Stack>
            {children}
            {isLoading && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  background: "transparent",
                  width: "100%",
                  left: 0,
                  top: 0,
                  "--LinearProgress-thickness": "4px",
                  "--LinearProgress-radius": "0px",
                  "--LinearProgress-progressRadius": "2px",
                }}
              />
            )}
          </Stack>
        </Card>
        <Typography level="body-sm" width="100%" textAlign="right">
          powered by{" "}
          <Link target="_blank" href="https://www.deepl.com/en/translator">
            DeepL
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
}
